import { Description, Field, Input, Label } from '@headlessui/react'
import clsx from 'clsx'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { WarningFilled } from '~/icons/warnings'

export type TextInputProps = {
	label: string
	name: string
	placeholder?: string
	helperText?: string
	errorMessage?: string
	disabled?: boolean
	readOnly?: boolean
	autoFocus?: boolean
	value?: string
	defaultValue?: string
	onChange?: (value: string) => void
	onInput?: (value: string) => void
	onBlur?: (value: string) => void
	layer?: 1 | 2
	hideLabel?: boolean
	register?: UseFormRegister<FieldValues>
	fullWidth?: boolean
	type?: 'text' | 'email' | 'password' | 'hidden'
	icon?: React.ReactNode
}

export default function TextInput({
	label,
	name,
	placeholder,
	helperText,
	errorMessage,
	disabled,
	readOnly,
	autoFocus,
	value,
	onChange,
	onInput,
	onBlur,
	layer = 1,
	defaultValue,
	hideLabel,
	register,
	fullWidth,
	type = 'text',
	icon
}: TextInputProps) {
	const bgColor = layer
		? layer === 1
			? 'bg-field-01'
			: 'bg-field-02'
		: 'bg-field-01'
	return (
		<Field
			className={clsx(
				'group flex w-full flex-col gap-2',
				type === 'hidden' && 'hidden'
			)}
		>
			<Label
				className={clsx(
					'text-small text-text-secondary group-has-[[disabled]]:text-text-disabled',
					disabled && 'text-text-disabled',
					hideLabel && 'hidden'
				)}
			>
				{label}
			</Label>
			<div className='relative w-full'>
				<Input
					name={name}
					placeholder={placeholder}
					disabled={disabled}
					readOnly={readOnly}
					autoFocus={autoFocus}
					value={value}
					type={type}
					defaultValue={defaultValue}
					onChange={e => onChange?.(e.target.value)}
					onInput={e => onInput?.(e.target.value)}
					onBlur={e => onBlur?.(e.target.value)}
					className={clsx(
						'h-12 w-full outline-none ring-inset',
						'block px-4',
						bgColor,
						'border-b border-border-strong-01',
						'placeholder:text-text-placeholder',
						'disabled:bg-field-01 disabled:text-text-on-color-disabled',
						'read-only:bg-[transparent]',
						'focus-visible:!border-[transparent] focus-visible:ring-3 focus-visible:ring-border-interactive',
						errorMessage &&
							'!border-[transparent] ring-3 ring-error',
						disabled && 'disabled:!border-[transparent]'
					)}
					{...(register && register(name))}
				/>
				{(errorMessage || icon) && (
					<div className='absolute bottom-0 right-0 top-0 flex items-center'>
						<WarningFilled
							className={clsx(
								'text-support-error',
								!icon && 'mr-4',
								!errorMessage && 'hidden'
							)}
						/>
						{icon}
					</div>
				)}
			</div>
			{helperText && !errorMessage && (
				<Description
					className={clsx(
						'text-small text-text-secondary',
						disabled && 'text-text-disabled',
						!label && 'hidden'
					)}
				>
					{helperText}
				</Description>
			)}
			{errorMessage && (
				<Description
					className={clsx(
						'text-small text-text-error group-has-[[disabled]]:text-text-disabled',
						disabled && 'text-text-disabled',
						!label && 'hidden'
					)}
				>
					{errorMessage}
				</Description>
			)}
		</Field>
	)
}
